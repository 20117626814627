import React from "react";
import { IComponent } from "store/types";
import styled from "styled-components";
import { NestedSubjectList } from "./NestedSubjectList";

interface IProps {
  nestedComponent: IComponent;
}

const OptionContent = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0rem;
  font-weight: normal;
`;

const FlexStretch = styled.div`
  flex: 1;
`;

export const NestedComponent = ({ nestedComponent }: IProps) => {
  return (
    <>
      {!!nestedComponent.children && (
        <FlexStretch>
          <OptionContent>
            <NestedSubjectList nestedComponent={nestedComponent} nestedSubjects={nestedComponent.children} />
          </OptionContent>
        </FlexStretch>
      )}
    </>
  );
};
