import React from "react";
import ReactDOM from "react-dom";
import { globalHistory, Router } from "@reach/router";
import { QueryParamProvider } from "use-query-params";
import { Provider } from "react-redux";
import { App } from "./containers/App";
import store from "./store";
import { ThemeProvider } from "@material-ui/core/styles";
import { defaultTheme } from "./theme/material";
import { Route } from "components/route";
import "./index.css";

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={defaultTheme}>
      <QueryParamProvider reachHistory={globalHistory}>
        <Router>
          <Route default component={App} />
        </Router>
      </QueryParamProvider>
    </ThemeProvider>
  </Provider>,

  document.getElementById("root") as HTMLElement,
);
