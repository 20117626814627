// TODO change definition once API data is known
export interface IMMSummary {
  name: string;
  code: string;
  year: number;
  status?: string;
  type: "Major" | "Minor" | "Specialisation";
}

export interface ICourseInstance {
  instanceCode: string;
  campus: string;
  campusCode: string;
  mms: IMMSummary[];
  year: number;
  name?: string;
  parentCode?: string;
  status?: string;
}

export interface ICourseSummary {
  code: string;
  name: string;
  years: number[];
  instances: ICourseInstance[];
}

export interface ICourseSelection {
  courseCode?: string | null;
  instanceCode?: string | null;
  instanceVersionNumber?: number | null;
  campusCode?: string | null;
  year?: number | null;
  mms1Code?: string | null;
  mms2Code?: string | null;
  mmsSCode?: string | null;
  parentCode?: string | null;
  campus?: string | null;
  status?: any;
}

export const enum ComponentSubType {
  Major = "Major",
  Minor = "Minor",
  Specialisation = "Specialisation",
  SecondMajor = "Second Major",
  SecondMinor = "Second Minor",
  Option = "Option",
  Course = "Course",
  PhantomMajor = "PhantomMMS",
  PhantomSecondMajor = "PhantomSecondMMS",
  PhantomSpecialisation = "PhantomSpecialisation",
  Specialisation1 = "Specialisation 1",
  Specialisation2 = "Specialisation 2",
  Curriculum1 = "Curriculum Method 1",
  Curriculum2 = "Curriculum Method 2",
  CurriculumSpecialisation = "Curriculum specialisation",
  Nested = "Nested",
}

export const enum ComponentType {
  Course = "Course",
  Major = "Major",
  Minor = "Minor",
  Specialisation = "Specialisation",
  Subject = "Subject",
  Capstone = "Capstone",
  Core = "Core",
  CoreChoice = "Core choice",
  Electives = "Electives",
  Level = "Level",
  Component = "Component",
}

export const enum QualificationType {
  Undergraduate = "Undergraduate",
  Postgraduate = "Postgraduate",
}

export interface IComponent {
  type: ComponentType;
  subType?: ComponentSubType;
  code?: string;
  title?: string;
  points?: string | number;
  description?: string;
  qualification?: QualificationType;
  version?: string;
  year?: string;
  ruleSummary?: string;
  ruleQuery?: string;
  data?: any;
  row?: any;
  children?: IComponent[];
  mms?: IComponent[];
  studyPeriods: string[];
  completionRequirements?: any;
  additionalInformation?: string;
  hasReqs?: boolean;
  isOffered?: boolean;
  campus?: string;
  status?: string;
}

export interface IError {
  action: string;
  message: string;
}

export interface ICourseConfig {
  instances: ICourseInstance[];
  years: Array<any>;
}

export interface IState {
  loadingCourseMap: boolean;
  loadingCourses: boolean;
  loadingCourseConfig: boolean;
  availableCourses: ICourseSummary[];
  courseConfig: ICourseConfig | null;
  courseSelection: ICourseSelection;
  courseMap: IComponent | null;
  error?: null | IError;
}
