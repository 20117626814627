import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as DownIcon } from "assets/icons/raw/24px/ltu-chevron-down.svg";
import { ReactComponent as UpIcon } from "assets/icons/raw/24px/ltu-chevron-up.svg";
import { brandBlack, grey3 } from "theme/colors";

interface IProps {
  disabled: boolean;
  open: boolean;
}

export const CustomSelectIcon = (props: IProps) => {
  const iconStyle = { color: props.disabled ? grey3.toString() : brandBlack.toString() };
  return (
    <SvgIcon className="MuiSelect-icon MuiSelect-iconOutlined">
      {props.open ? <UpIcon style={iconStyle} /> : <DownIcon style={iconStyle} />}
    </SvgIcon>
  );
};
