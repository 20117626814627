import { get } from "lib/fetch";

export const GET_COURSE_CONFIG = "courseConfig/GET_COURSE_CONFIG";

export const getCourseConfig = () => ({
  type: GET_COURSE_CONFIG,
  payload: get(`/course-config`).then((response) => {
    if (response.data && response.data.instances && response.data.years) {
      return response.data;
    } else {
      return [];
    }
  }),
});
