import React from "react";
import { ComponentType, IComponent } from "store/types";
import styled from "styled-components";
import { NestedComponent } from "./NestedComponent";

interface IProps {
  nestedComponents: IComponent[];
}

const MultiSubjectContainer = styled.div`
  margin-top: 1.5rem;
`;

const ElectivesDetails = styled.p`
  margin: 0;
  padding: 0;
`;

export const NestedComponentList = ({ nestedComponents }: IProps) => {
  return (
    <>
      {nestedComponents.map((n, i) => (
        <MultiSubjectContainer key={`${n.subType}-option-component-${i}`}>
          {n.type === ComponentType.Electives && (
            <div>
              <ElectivesDetails>
                <b>{n.type}</b>
              </ElectivesDetails>
              <ElectivesDetails>{n.ruleSummary}</ElectivesDetails>
            </div>
          )}
          {n.type !== ComponentType.Electives && <NestedComponent nestedComponent={n} />}
        </MultiSubjectContainer>
      ))}
    </>
  );
};
