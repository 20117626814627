import { IState } from "./types";

const initialState: IState = {
  loadingCourseMap: false,
  loadingCourses: false,
  loadingCourseConfig: false,
  availableCourses: [],
  courseConfig: null,
  courseSelection: {},
  courseMap: null,
  error: null,
};

export default initialState;
