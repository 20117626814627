import React from "react";
import styled from "styled-components";
import logo from "assets/logo.svg";

const LogoElement = styled.img`
  @media (max-width: 731px) {
    width: 4rem;
  }
  @media (max-width: 823px) {
    width: 6rem;
  }
  @media (max-width: 1024px) {
    width: 8rem;
  }
  width: 12rem;
`;

interface IProps {
  className?: string;
}

export const Logo = (props: IProps) => <LogoElement className={props.className} src={logo} alt="logo" />;
