import { brandBlack, brandRed, grey3 } from "./colors";
import { createTheme } from "@material-ui/core/styles";

export const defaultTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: brandRed.toString(),
    },
    secondary: {
      main: brandBlack.toString(),
    },
  },
  props: {
    MuiInput: {
      disableUnderline: true,
    },
    MuiInputLabel: {
      shrink: true,
    },
  },
  overrides: {
    MuiPaper: {
      elevation2: {
        boxShadow: "none",
      },
    },
    MuiFormLabel: {
      root: {
        color: brandBlack.toString(),
        fontSize: "16px",
        fontWeight: "bold",
        "&$disabled": {
          color: "black important!",
        },
      },
      asterisk: {
        color: brandRed.toString(),
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        border: "0px !important",
      },
    },
    MuiInputBase: {
      root: {
        border: `solid 2px ${grey3.toString()}`,
        borderRadius: "0px !important",
        margin: "0.25rem 0",
        color: brandBlack.toString(),
      },
    },
    MuiChip: {
      root: {
        fontSize: "12px",
        lineHeight: "14px",
        color: brandBlack.toString(),
      },
      outlined: {
        border: `solid 1px ${brandBlack.toString()}`,
      },
    },
    MuiSelect: {
      root: {
        padding: "1rem !important",
      },
    },
    ...({
      MuiAutocomplete: {
        inputRoot: {
          paddingTop: "0rem !important",
          paddingLeft: "0rem !important",
          paddingBottom: "0rem !important",
        },
        input: {
          paddingTop: "1rem !important",
          paddingLeft: "1rem !important",
          paddingBottom: "1rem !important",
        },
      },
    } as any),
  },
});
