import { Logo } from "components/logo/Logo";
import React from "react";
import styled from "styled-components";

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 50px;
`;

const Heading = styled.h1`
  margin-bottom: 0.5em;
`;

const A = styled.a`
  color: #242424 !important;
`;

export const CourseSelectionHeader = () => {
  return (
    <>
      <HeaderContainer className="do-not-print">
        <Logo />
        <Heading>Study Plan Guides</Heading>
      </HeaderContainer>
      <p className="do-not-print">
        Access a Study Plan Guide for your Course and Major. Need help navigating your Study Plan Guide? Visit the{" "}
        <A
          href="https://www.latrobe.edu.au/students/your-course/study-plan-guides"
          title="Study Plan Guides Help"
          role="link"
          target="_blank"
        >
          Study Plan Guides support webpage
        </A>
        .
      </p>
    </>
  );
};
