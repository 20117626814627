import React from "react";

import { Chip, createStyles, makeStyles } from "@material-ui/core";
import styled from "styled-components";
import { uniq } from "lodash";
import { subjectHandbookUrl } from "lib/handbook";
import { useSelector } from "react-redux";
import { IState } from "store/types";

interface IProps {
  isOffered?: boolean;
  year?: string;
  subjectCode?: string;
  studyPeriods?: string[] | null;
}

const StudyPeriodsContainer = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 6px;
  padding-left: 0px;
`;

const A = styled.a`
  color: #242424 !important;
`;

export const chipStyles = makeStyles(() =>
  createStyles({
    chip: {
      margin: "0 0.25rem 0 0",
      height: "24px",
    },
    label: {
      paddingLeft: "6px",
      paddingRight: "6px",
    },
  }),
);

export const StudyPeriods = ({ isOffered, year, subjectCode, studyPeriods }: IProps) => {
  const terms = uniq((studyPeriods ?? []).filter(Boolean));
  const classes = chipStyles();

  const instanceCode = useSelector((s: IState) => s.courseSelection.instanceCode);
  const handbookUrl = subjectHandbookUrl(subjectCode, year, instanceCode);

  return (
    <>
      <StudyPeriodsContainer aria-label="Available in.">
        {(!isOffered || terms.length === 0) && (
          <Chip
            component="li"
            classes={{ root: classes.chip, label: classes.label }}
            variant="outlined"
            label={
              <A href={handbookUrl} target="_">
                Refer to the Handbook
              </A>
            }
          />
        )}
        {isOffered && terms.length > 3 && (
          <Chip
            component="li"
            aria-label="Multiple instances."
            className={classes.chip}
            variant="outlined"
            label={
              <A href={handbookUrl} target="_">
                Multiple instances
              </A>
            }
          />
        )}
        {isOffered &&
          terms.length > 0 &&
          terms.length <= 3 &&
          terms.map((studyPeriod: string, i: number) => (
            <Chip
              component="li"
              classes={{ root: classes.chip, label: classes.label }}
              key={`${studyPeriod}-${i}`}
              variant="outlined"
              aria-label={`${studyPeriod}.`}
              label={studyPeriod}
            />
          ))}
      </StudyPeriodsContainer>
    </>
  );
};
