import React from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

export const AppRoot = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
` as any;

export const AppContent = styled.div`
  flex: 1;
  width: 100%;
  min-heigth: 12rem;
  border: tpx solid green;
` as any;

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
` as any;

export const StackStrechChild = styled.div`
  flex: 1;
`;

export const ResponsiveCenter = ({ children }: any) => (
  <Grid tabIndex={-1} container spacing={0} alignItems="center" justifyContent="center">
    <Grid tabIndex={-1} item xs={11} sm={11} md={9} xl={7}>
      {children}
    </Grid>
  </Grid>
);
