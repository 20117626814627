import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { ComponentSubType } from "store/types";
import { brandRed } from "theme/colors";

export const typeColors: any = {
  [ComponentSubType.Course]: "#00737E",
  [ComponentSubType.Major]: "#16407A",
  [ComponentSubType.PhantomMajor]: "#16407A",
  [ComponentSubType.PhantomSecondMajor]: "#3E78BC",
  [ComponentSubType.PhantomSpecialisation]: "#16407A",
  [ComponentSubType.Specialisation1]: "#16407A",
  [ComponentSubType.Curriculum1]: "#16407A",
  [ComponentSubType.CurriculumSpecialisation]: "#16407A",
  [ComponentSubType.SecondMajor]: "#3E78BC",
  [ComponentSubType.Specialisation2]: "#3E78BC",
  [ComponentSubType.Curriculum2]: "#3E78BC",
  [ComponentSubType.Minor]: "#420074",
  [ComponentSubType.SecondMinor]: "#756AB9",
  [ComponentSubType.Option]: "#53565A",
  [ComponentSubType.Specialisation]: "#16407A",
};

const headerContentColor = (props: any, theme: Theme) => {
  return theme.palette.primary.contrastText;
};

const backgroundColour = (props: any, theme: Theme) => {
  const defaultColor = theme.palette.primary.contrastText;
  return typeColors[props.type] ?? defaultColor;
};

export const slotCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      border: (props) => `1px solid ${backgroundColour(props, theme)}`,
      borderRadius: 0,
      padding: 0,
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    cardHeader: {
      background: (props) => backgroundColour(props, theme),
      color: (props) => headerContentColor(props, theme),
      paddingTop: "0.1rem",
      paddingBottom: "0.1rem",
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem",
      height: "2rem",
    },
    cardTitle: {
      fontSize: "0.75rem",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    cardContent: {
      paddingTop: "0.15rem",
      paddingBottom: "0.5rem !important",
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem",
      height: "calc(100% - 3rem)",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      color: (props) => backgroundColour(props, theme),
    },
    cardActions: {
      borderTop: "1px solid #E7E8E7",
      paddingLeft: 0,
      paddingRight: 0,
      height: "1rem",
    },
    cardActionButton: {
      width: "100%",
      paddingLeft: 0,
      paddingRight: 0,
      // height: "1rem",
    },
    cardActionButtonLabel: {
      width: "100%",
      paddingLeft: "0.75rem",
      justifyContent: "flex-start",
      textTransform: "initial",
      textDecoration: "underline",
      color: brandRed.toString(),
      "&:hover": {
        color: brandRed.darken(0.2).toString(),
      },
    },
  }),
);
