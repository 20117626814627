import React from "react";
import { IComponent } from "store/types";
import styled from "styled-components";
import { NestedComponentList } from "./NestedComponentList";

interface IProps {
  nestedOption: IComponent;
}

const OptionName = styled.p`
  margin-top: 0.5rem;
  margin-bottom: 0rem;
  font-weight: bold;
`;

const OptionContent = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0rem;
  font-weight: normal;
`;

const FlexStretch = styled.div`
  flex: 1;
`;

const OptionContainer = styled.div`
  border: 1px solid;
  padding: 10px;
`;

export const NestedOption = ({ nestedOption }: IProps) => {
  return (
    <>
      <OptionContainer>
        {!!nestedOption.children && (
          <FlexStretch>
            <OptionName role="text" aria-label={`option title: ${nestedOption.title}.`}>
              {nestedOption.title}:
            </OptionName>
            <OptionContent>
              <NestedComponentList nestedComponents={nestedOption.children} />
            </OptionContent>
          </FlexStretch>
        )}
      </OptionContainer>
    </>
  );
};
