import { Stack, ResponsiveCenter } from "components/Layout";
import React from "react";

export const CourseSelectionFooter = () => {
  return (
    <footer className="print-on-single-page">
      <ResponsiveCenter>
        <Stack>
          <p>
            Disclaimer: Every effort has been made to ensure the information provided in the Study Plan Guides are
            accurate at time of publication. Always refer to the published course, major or minor structure via the La
            Trobe <a href="https://handbook.latrobe.edu.au/">Handbook</a> for the most up to date information. The
            course structures within the guide may vary in accordance with course requirements, including associated
            majors, minors and specialisations. The University does not give any warranties in relation to the accuracy
            and completeness of the contents; nor does it accept responsibility for any loss or damage occasioned by use
            of the information contained in this publication. If you require further assistance please contact{" "}
            <a href="https://www.latrobe.edu.au/ask-us">ASK La Trobe</a>. CRICOS Provider 00115M
          </p>
          <br />
        </Stack>
      </ResponsiveCenter>
    </footer>
  );
};
