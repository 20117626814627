import React from "react";
import { ICourseSelection, QualificationType } from "store/types";
import styled from "styled-components";

interface IProps {
  qualification?: QualificationType;
  courseSelection: ICourseSelection;
}

const Heading = styled.p`
  font-weight: bold;
  margin-block: 0px;
` as any;

const Description = styled.p`
  margin-block-start: 6px;
  margin-block-end: 20px;
` as any;

export const YearOneCompulsorySubjects = ({ qualification, courseSelection }: IProps) => {
  let msg = "";

  if (qualification && qualification.startsWith(QualificationType.Postgraduate)) {
    if (!courseSelection.year || courseSelection.year >= 2024) {
      msg =
        "Academic Integrity Module (LTU0AIM) and Respectful Behaviour and Culture (RPC8010) are compulsory subjects that must be completed in your first semester of study.";
    } else {
      msg =
        "Academic Integrity Module (LTU0AIM) is a compulsory subject that must be completed in your first semester of study.";
    }
  } else if (!courseSelection.year || courseSelection.year >= 2024) {
    msg =
      "Academic Integrity Module (LTU0AIM), Wominjeka La Trobe: Indigenous Cultural Literacy for Higher Education (ABS0WOM) and Respectful Behaviour and Culture (RPC8010) are compulsory subjects that must be completed in your first semester of study.";
  } else {
    msg =
      "Academic Integrity Module (LTU0AIM) and Wominjeka La Trobe: Indigenous Cultural Literacy for Higher Education (ABS0WOM) are compulsory subjects that must be completed in your first semester of study.";
  }
  return (
    <>
      <Heading>Compulsory Online Subjects</Heading>
      <Description>{msg}</Description>
    </>
  );
};
