import React from "react";
import { IComponent, IState } from "store/types";
import styled from "styled-components";
import { toSentenceCase } from "lib/utils";
import { StudyPeriods } from "./StudyPeriods";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";
import { subjectHandbookUrl } from "lib/handbook";

interface IProps {
  subject: IComponent;
}

const SubjectName = styled.p`
  margin-top: 0.5rem;
  margin-bottom: 0rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  & a {
    color: inherit;
  }
`;

const SubjectDescription = styled.p`
  margin-top: 0.1rem;
`;

const FlexStretch = styled.div`
  flex: 1;
`;

export const Subject = ({ subject }: IProps) => {
  const instanceCode = useSelector((s: IState) => s.courseSelection.instanceCode);
  const handbookUrl = subjectHandbookUrl(subject.code, subject.year, instanceCode);

  return (
    <>
      <FlexStretch>
        <SubjectName role="text" aria-label={`Subject code: ${subject.code}.`}>
          <a
            href={handbookUrl}
            title={`View subject ${toSentenceCase(subject.title)} in the handbook.`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {subject.code}
          </a>
          {subject.hasReqs ? (
            <>
              &nbsp;&nbsp;
              <Tooltip placement="top" arrow title="Requisites apply to this subject. Please check handbook.">
                <span>
                  <a
                    href={handbookUrl}
                    title={`Review requisites for ${toSentenceCase(subject.title)} in the handbook.`}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    rel="noopener noreferrer"
                  >
                    <InfoOutlinedIcon fontSize="small" style={{ marginTop: "4px" }} />
                  </a>
                </span>
              </Tooltip>
            </>
          ) : null}
        </SubjectName>
        <SubjectDescription role="text" aria-label={`${toSentenceCase(subject.title)}.`}>
          {toSentenceCase(subject.title)}
        </SubjectDescription>
      </FlexStretch>
      <StudyPeriods
        isOffered={subject.isOffered}
        year={subject.year}
        subjectCode={subject.code}
        studyPeriods={subject.studyPeriods}
      />
    </>
  );
};
