import React from "react";
import { ComponentType, IComponent } from "store/types";
import styled from "styled-components";
import { StudyPeriods } from "./StudyPeriods";

interface IProps {
  nestedComponent: IComponent;
  nestedSubject: IComponent;
}

const SubjectContainer = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0rem;
`;

const SubjectTypeAndCode = styled.div`
  margin-top: 0.1rem;
  font-weight: bold;
`;

const SubjectDetails = styled.p`
  margin: 0;
  padding: 0;
`;

const FlexStretch = styled.div`
  flex: 1;
`;

export const NestedSubject = ({ nestedComponent, nestedSubject }: IProps) => {
  return (
    <>
      <FlexStretch>
        {nestedSubject.type === ComponentType.Subject && (
          <SubjectContainer role="text" aria-label={`Subject code: ${nestedSubject.code}.`}>
            <SubjectTypeAndCode>
              {nestedComponent.type}:{nestedSubject.code}
            </SubjectTypeAndCode>
            <SubjectDetails>{nestedSubject.title}</SubjectDetails>
            <SubjectDetails>{nestedSubject.points} credit points</SubjectDetails>
            <StudyPeriods
              isOffered={nestedSubject.isOffered}
              year={nestedSubject.year}
              subjectCode={nestedSubject.code}
              studyPeriods={nestedSubject.studyPeriods}
            />
          </SubjectContainer>
        )}
      </FlexStretch>
    </>
  );
};
