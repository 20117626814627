import React from "react";
import { IComponent } from "store/types";
import styled from "styled-components";
import { NestedSubject } from "./NestedSubject";

interface IProps {
  nestedComponent: IComponent;
  nestedSubjects: IComponent[];
}

const MultiSubjectContainer = styled.div`
  margin-top: 1.5rem;
`;

export const NestedSubjectList = ({ nestedComponent, nestedSubjects }: IProps) => {
  return (
    <>
      {nestedSubjects.map((s, i) => (
        <MultiSubjectContainer key={`${s.subType}-option-subject-${i}`}>
          <NestedSubject nestedComponent={nestedComponent} nestedSubject={s} />
        </MultiSubjectContainer>
      ))}
    </>
  );
};
