import { get } from "lib/fetch";

export const GET_COURSE_MAP = "courseMap/GET_COURSE_MAP";

export const getCourseMap = (
  courseCode: any,
  instanceCode: string,
  instanceVersionNumber: number,
  campusCode: any,
  campus: any,
  year: number,
  status: string,
  mms1Code?: string | null,
  mms2Code?: string | null,
  mmsSCode?: string | null,
) => ({
  type: GET_COURSE_MAP,
  payload: get(
    `/map?course=${courseCode}&instance=${instanceCode}&instanceVersionNumber=${instanceVersionNumber}&year=${year}&status=${status}&campus=${campus}&campusCode=${campusCode}${
      mms1Code ? `&major=${mms1Code}` : ""
    }${mms2Code ? `&major=${mms2Code}` : ""}${mmsSCode ? `&specialisation=${mmsSCode}` : ""}&format=json`,
  ),
});
