import { fulfilled, failed, pending } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import { GET_AVAILABLE_COURSES } from "actions/availableCourses";

const loadingCourses = (state = initialState.loadingCourses, action: any) => {
  switch (action.type) {
    case pending(GET_AVAILABLE_COURSES):
      return true;
    case fulfilled(GET_AVAILABLE_COURSES):
    case failed(GET_AVAILABLE_COURSES):
      return false;
    default:
      return state;
  }
};

export default loadingCourses;
