import React from "react";
import { ComponentSubType, ComponentType, IState } from "store/types";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";
import { brandBlack } from "theme/colors";
import { typeColors } from "components/cardStyles";
import { useSelector } from "react-redux";

interface ILegend {
  type: string;
  description: string;
  colour: string;
  alternateDisplayType?: string;
}

const LegendWrapper = styled.div`
  padding-top: 35px;
` as any;

const LegendRow = styled.div`
  padding-bottom: 10px;
` as any;
const LegendH = styled.h3`
  font-size: 14.4px;
  line-height: 19px;
  margin-top: 2em;
  font-weight: 900;
` as any;

const legend = [
  {
    type: ComponentType.Core,
    description: "Required subject. Contains essential knowledge for your course.",
    colour: typeColors[ComponentSubType.Course],
  },
  {
    type: ComponentType.CoreChoice,
    description: "Defined subject options within a core sequence.",
    colour: typeColors[ComponentSubType.Course],
  },
  {
    type: ComponentType.Capstone,
    description: "Consolidating learning experience for final year of program.",
    colour: typeColors[ComponentSubType.Course],
  },
  {
    type: ComponentType.Electives,
    description: "Open or discipline-specific subject options within a core sequence.",
    colour: typeColors[ComponentSubType.Course],
    alternateDisplayType: "Elective",
  },
  {
    type: `${ComponentSubType.Major}`,
    description: "A required sequence of subjects to allow for depth of study in a specific discipline.",
    colour: typeColors[ComponentSubType.Major],
  },
  {
    type: `${ComponentSubType.Specialisation}`,
    description: "A required sequence of subjects to allow for depth of study in a specific discipline.",
    colour: typeColors[ComponentSubType.Specialisation],
  },
  {
    type: `${ComponentSubType.PhantomSpecialisation}`,
    description: "A required sequence of subjects to allow for depth of study in a specific discipline.",
    colour: typeColors[ComponentSubType.Specialisation],
  },
  {
    type: `${ComponentSubType.PhantomMajor}`,
    description: "A required sequence of subjects to allow for depth of study in a specific discipline.",
    colour: typeColors[ComponentSubType.Major],
    alternateDisplayType: ComponentSubType.Major,
  },
  {
    type: `${ComponentSubType.PhantomSecondMajor}`,
    description: "A required sequence of subjects to allow for depth of study in a specific discipline.",
    colour: typeColors[ComponentSubType.SecondMajor],
    alternateDisplayType: ComponentSubType.SecondMajor,
  },
  {
    type: `${ComponentSubType.Major} ${ComponentType.Core}`,
    description: "Required subject for your chosen major. Contains essential knowledge for your course.",
    colour: typeColors[ComponentSubType.Major],
  },
  {
    type: `${ComponentSubType.Major} ${ComponentType.CoreChoice}`,
    description: "Defined subject options within a major sequence.",
    colour: typeColors[ComponentSubType.Major],
  },
  {
    type: `${ComponentSubType.Major} ${ComponentType.Capstone}`,
    description: "Consolidating learning experience for final year of major.",
    colour: typeColors[ComponentSubType.Major],
  },
  {
    type: `${ComponentSubType.Specialisation1}`,
    description: "A sequence of subjects to allow for a greater depth of understanding in a specific discipline.",
    colour: typeColors[ComponentSubType.Specialisation1],
  },
  {
    type: `${ComponentSubType.CurriculumSpecialisation}`,
    description: "A sequence of subjects related to your teaching discipline(s).",
    colour: typeColors[ComponentSubType.CurriculumSpecialisation],
  },
  {
    type: `${ComponentSubType.Curriculum1}`,
    description:
      "Curriculum Method subjects are designed to establish a knowledge and skill base for pre-service teachers in the areas of curriculum, pedagogy, planning, assessment and reporting.",
    colour: typeColors[ComponentSubType.Curriculum1],
  },
  {
    type: `${ComponentSubType.SecondMajor} ${ComponentType.Core}`,
    description: "Defined subject within a major sequence. ",
    colour: typeColors[ComponentSubType.SecondMajor],
  },
  {
    type: `${ComponentSubType.SecondMajor} ${ComponentType.CoreChoice}`,
    description: "Defined subject options within a second, optional major sequence.",
    colour: typeColors[ComponentSubType.SecondMajor],
  },
  {
    type: `${ComponentSubType.SecondMajor} ${ComponentType.Capstone}`,
    description: "Consolidating learning experience for final year of second, optional major.",
    colour: typeColors[ComponentSubType.SecondMajor],
  },
  {
    type: `${ComponentSubType.Specialisation2}`,
    description: "A sequence of subjects to allow for a greater depth of understanding in a specific discipline.",
    colour: typeColors[ComponentSubType.SecondMajor],
  },
  {
    type: `${ComponentSubType.Curriculum2}`,
    description:
      "Curriculum Method subjects are designed to establish a knowledge and skill base for pre-service teachers in the areas of curriculum, pedagogy, planning, assessment and reporting.",
    colour: typeColors[ComponentSubType.Curriculum2],
  },
  {
    type: `${ComponentSubType.Minor} ${ComponentType.Core}`,
    description: "Required subject within an optional minor sequence.",
    colour: typeColors[ComponentSubType.Minor],
  },
  {
    type: `${ComponentSubType.Minor} ${ComponentType.CoreChoice}`,
    description: "Defined subject options within an optional minor sequence.",
    colour: typeColors[ComponentSubType.Minor],
  },
  {
    type: `${ComponentSubType.SecondMinor} ${ComponentType.Core}`,
    description: "Required subject within an optional, second minor sequence.",
    colour: typeColors[ComponentSubType.SecondMinor],
  },
  {
    type: `${ComponentSubType.SecondMinor} ${ComponentType.CoreChoice}`,
    description: "Defined subject options within an optional, second minor sequence.",
    colour: typeColors[ComponentSubType.SecondMinor],
  },
  {
    type: ComponentSubType.Option,
    description:
      "Unspecified 'space' in a course structure for students to select an appropriate component type: majors, minors or electives.",
    colour: typeColors[ComponentSubType.Option],
  } as ILegend,
];

const backgroundColour = (props: any, theme: Theme) => {
  const defaultColor = theme.palette.primary.contrastText;
  const typeColor = legend.find((l) => l.type === props.type)?.colour;
  return typeColor ? typeColor : defaultColor;
};

const legendStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {},
    type: {
      fontSize: "0.75rem",
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "white",
      backgroundColor: (props) => backgroundColour(props, theme),
      border: (props) => `0.5px solid ${backgroundColour(props, theme)}`,
      flex: 1,
      padding: "4px 8px 4px 8px",
    },
    description: {
      fontSize: "16px",
      border: (props) => `0.5px solid ${backgroundColour(props, theme)}`,
      color: brandBlack.toString(),
      flex: 1,
      padding: "4px 8px 4px 8px",
    },
  }),
);

export const Legend = () => {
  // We need to filter the legend to only show items relevant to this course map
  const courseMap = useSelector((state: IState) => state.courseMap);
  const itemsOnCourseMap = new Set();
  courseMap &&
    courseMap.children &&
    courseMap.children.forEach((yearComponent) => {
      yearComponent.children &&
        yearComponent.children.forEach((slot) => {
          if (!slot.subType) {
            itemsOnCourseMap.add(slot.type);
          } else if (slot.subType) {
            if (
              [
                ComponentSubType.Option,
                ComponentSubType.Specialisation,
                ComponentSubType.Specialisation1,
                ComponentSubType.Specialisation2,
                ComponentSubType.Curriculum1,
                ComponentSubType.Curriculum2,
                ComponentSubType.CurriculumSpecialisation,
              ].includes(slot.subType)
            ) {
              itemsOnCourseMap.add(slot.subType);
            } else if (slot.subType === ComponentSubType.Course) {
              itemsOnCourseMap.add(slot.type);
            } else if (slot.subType === ComponentSubType.PhantomSpecialisation) {
              itemsOnCourseMap.add(ComponentSubType.Specialisation);
            } else if (slot.subType === ComponentSubType.PhantomMajor) {
              itemsOnCourseMap.add(ComponentSubType.Major);
            } else if (slot.subType === ComponentSubType.PhantomSecondMajor) {
              itemsOnCourseMap.add(ComponentSubType.PhantomSecondMajor);
            } else itemsOnCourseMap.add(`${slot.subType} ${slot.type}`);
          }
        });
    });

  if (itemsOnCourseMap.size === 0) {
    return null;
  }
  return (
    <LegendWrapper className="print-on-single-page">
      <LegendH>LEGEND</LegendH>
      {legend
        .filter((row) => itemsOnCourseMap.has(row.type))
        .map((row, i) => {
          const classes = legendStyles({ type: row.type });
          return (
            <LegendRow key={i}>
              <Grid tabIndex={-1} container>
                <Grid tabIndex={-1} item xs={3} sm={2} className={classes.type}>
                  {row.alternateDisplayType ? row.alternateDisplayType : row.type}
                </Grid>
                <Grid item xs={9} sm={10} className={classes.description}>
                  {row.description}
                </Grid>
              </Grid>
            </LegendRow>
          );
        })}
    </LegendWrapper>
  );
};
