import { useDispatch, useSelector } from "react-redux";
import { IState } from "store/types";
import React, { useEffect } from "react";
import { getCourseMap } from "actions/courseMap";
import { CourseYear } from "./CourseYear";
import styled from "styled-components";
import { validationError } from "theme/colors";
import { Legend } from "./CourseMapLegend";
import { Aligned } from "components/aligned/alignedDiv";
import { Logo } from "components/logo/Logo";
import { toSentenceCase } from "lib/utils";
import { CourseAdditionalDetails } from "./CourseAdditionalDetails";
import { toggledFeatures, FeatureToggle } from "config";

const CourseMapWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

const Error = styled.div`
  color: ${validationError.toString()};
`;

const StudyGuideTitle = styled.h2`
  font-size: 28.125px;
  font-weight: 500;
  line-height: 33px;
  margin-top: 2em;
  color: #53565a;
`;

const CoursePlanTitle = styled.h2`
  margin-top: 0em;
  margin-bottom: 0em;
`;

export const CourseMap = () => {
  const dispatch = useDispatch();

  // What we need from Redux
  const courseSelection = useSelector((state: IState) => state.courseSelection);
  const loading = useSelector((state: IState) => state.loadingCourseMap || state.loadingCourseConfig);
  const error = useSelector((state: IState) => state.error);
  const courseMap = useSelector((state: IState) => state.courseMap);

  // When the selction changes - load a coursemap from the API
  useEffect(() => {
    if (courseSelection.instanceCode && courseSelection.year && courseSelection.instanceVersionNumber) {
      dispatch(
        getCourseMap(
          courseSelection.courseCode,
          courseSelection.instanceCode,
          courseSelection.instanceVersionNumber,
          courseSelection.campusCode,
          courseSelection.campus,
          courseSelection.year,
          courseSelection.status,
          courseSelection.mms1Code,
          courseSelection.mms2Code,
          courseSelection.mmsSCode,
        ),
      );
    }
  }, [
    courseSelection.courseCode,
    courseSelection.instanceCode,
    courseSelection.instanceVersionNumber,
    courseSelection.campusCode,
    courseSelection.campus,
    courseSelection.year,
    courseSelection.status,
    courseSelection.mms1Code,
    courseSelection.mms2Code,
    courseSelection.mmsSCode,
    dispatch,
  ]);

  if (error && !loading) {
    return (
      <CourseMapWrapper>
        <Error>
          <strong>Error:</strong> {error.message}
        </Error>
      </CourseMapWrapper>
    );
  }

  if (!courseSelection.courseCode || !courseSelection.instanceCode || !courseSelection.year) {
    // TODO return some label inviting user to select more
    return null;
  }

  if (loading || !courseMap) {
    return null;
  }

  const buildCourseMapTitle = () => {
    let title = "";
    if (courseMap.title) {
      title = `${toSentenceCase(courseMap.title)} ${courseSelection.instanceCode} ${courseMap.campus ?? ""} ${
        !!courseMap.status ? `(${courseMap.status})` : ""
      } `;
      if (courseMap.mms && courseMap.mms.length > 0) {
        if (courseMap.mms[0].title) {
          title += ` (${courseMap.mms[0].title}`;
          if (courseMap.mms[1] && courseMap.mms[1].title) {
            title += ` & ${courseMap.mms[1].title}`;
          }
          title += `)`;
        }
      }
    }
    return title;
  };

  const enableAdditionalDetailsFeature = toggledFeatures.includes(FeatureToggle.COURSE_MAP_ADDITIONAL_INFORMATION);
  const displayAdditionalDetails =
    enableAdditionalDetailsFeature && (courseMap.completionRequirements || courseMap.additionalInformation);

  return (
    <CourseMapWrapper>
      <Aligned style={{ justifyContent: "space-between" }}>
        <div>
          <StudyGuideTitle tabIndex={0}>{courseMap.year} Study Plan Guide</StudyGuideTitle>
          <CoursePlanTitle tabIndex={0}>{buildCourseMapTitle()}</CoursePlanTitle>
        </div>
        <Logo className="print-only" aria-hidden="true" />
      </Aligned>
      {(courseMap.children ?? []).map((yearComponent) => (
        <CourseYear
          key={`${yearComponent.title}-${yearComponent.code}-${yearComponent.version}`}
          yearComponent={yearComponent}
          qualification={courseMap.qualification}
          courseSelection={courseSelection}
        />
      ))}
      <Legend />
      {displayAdditionalDetails && (
        <CourseAdditionalDetails
          completionRequirements={courseMap.completionRequirements}
          additionalInformation={courseMap.additionalInformation}
        />
      )}
    </CourseMapWrapper>
  );
};
