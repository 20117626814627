import { fulfilled, failed, pending } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import { GET_COURSE_CONFIG } from "actions/courseConfig";

const loadingCourseConfig = (state = initialState.courseConfig, action: any) => {
  switch (action.type) {
    case pending(GET_COURSE_CONFIG):
      return true;
    case fulfilled(GET_COURSE_CONFIG):
    case failed(GET_COURSE_CONFIG):
      return false;
    default:
      return state;
  }
};

export default loadingCourseConfig;
