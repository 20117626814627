import React, { useState, useCallback } from "react";
import { IComponent, ICourseSelection, IState } from "store/types";
import { Grid, GridSize } from "@material-ui/core";
import { Slot } from "./slot/Slot";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getMaxUnitPointSize } from "lib/units";
import { chunk, includes, noop, range, times } from "lodash";
import { useBreakpoint } from "lib/useBreakpoint";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

interface IProps {
  slots: IComponent[];
  courseSelection: ICourseSelection;
}

const gridWidth = (c: IComponent) => (3 * Number(c.points ?? 15)) / 15;
const xsGridWidth = (c: IComponent, maxUnitSz: number) => (gridWidth(c) * 4) / (maxUnitSz / 15);

const computeExpansion = (
  expandedSlotIndexes: boolean[],
  maxUnitSz: number,
  changingIndex: number,
  breakpoint: Breakpoint,
  slots: IComponent[],
): boolean[] => {
  // newState - are we setting to expanded or not?
  const newState = !expandedSlotIndexes[changingIndex];

  // The UI row size - depends on screen size and the biggest slot size
  const actualRowSize = breakpoint === "xs" || breakpoint === "sm" ? Math.round(maxUnitSz / 15) : 4;
  const result = [...expandedSlotIndexes];

  // Will convert [0,1,2] to [0, 1, 1, 2] if 1 is 30 points
  const scaledIndexes: number[] = [];
  for (const slotIndex of range(0, result.length)) {
    const slot = slots[slotIndex];
    const slotSize = Number(slot.points || 15) / 15;
    times(slotSize, () => scaledIndexes.push(slotIndex));
  }

  // The indices of slots in the same UI row (depending on screen size)
  const actualRow = chunk(scaledIndexes, actualRowSize).find((indexes) => includes(indexes, changingIndex)) ?? [];

  for (const slotIndex of actualRow) {
    result[slotIndex] = newState;
  }
  return result;
};

const YearRowWrapper = styled.div`
  margin-bottom: 0.5rem;
  width: 100%;
`;

export const YearRow = ({ slots, courseSelection }: IProps) => {
  const maxUnitSz = useSelector((s: IState) => getMaxUnitPointSize(s.courseMap));
  const [expandedSlotIndexes, setExpandedSlotIndexes] = useState<boolean[]>(slots.map((_s) => false));
  const breakpoint = useBreakpoint();
  const makeFlipExpansion = useCallback(
    (i: number) => () => setExpandedSlotIndexes((old) => computeExpansion(old, maxUnitSz, i, breakpoint, slots)),
    [breakpoint, maxUnitSz, slots],
  );

  return (
    <YearRowWrapper tabIndex={-1}>
      <Grid role="grid" container spacing={1} className="do-not-print">
        {slots.map((slot, i) => (
          <Grid item role="row" key={i} xs={xsGridWidth(slot, maxUnitSz) as GridSize} md={gridWidth(slot) as GridSize}>
            <Slot
              expanded={!!expandedSlotIndexes[i]}
              flipExpansion={makeFlipExpansion(i)}
              slot={slot}
              courseSelection={courseSelection}
            />
          </Grid>
        ))}
      </Grid>

      {/* The below will show a grid for printing */}
      <Grid container spacing={1} className="print-only-flex" aria-hidden="true" role="none">
        {slots.map((slot, i) => (
          <Grid item key={i} xs={gridWidth(slot) as GridSize}>
            <Slot expanded flipExpansion={noop} slot={slot} courseSelection={courseSelection} />
          </Grid>
        ))}
      </Grid>
    </YearRowWrapper>
  );
};
