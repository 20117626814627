import { color } from "csx";

/** Latrobe colors */
export const brandBlack = color("#242424");
export const brandRed = color("#e2231b");

export const grey1 = color("#fafafa");
export const grey2 = color("#eeeeee");
export const grey3 = color("#cccccc");
export const grey4 = color("#999999");
export const grey5 = color("#6C6C6C");
export const grey6 = color("#414141");

export const validationInfo = color("#0098aa");
export const validationSuccess = color("#52c41a");
export const validationError = color("#e2231b");
export const validationWarning = color("ff9700");
