import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ICourseInstance, IMMSummary, IState } from "store/types";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  selectInstance,
  selectMMS1,
  selectMMS2,
  selectMMSS,
  selectYear,
  setCourseSelection,
} from "actions/courseSelection";
import { useQueryParams, NumberParam, StringParam } from "use-query-params";
import { useState } from "react";
import { CustomSelectIcon } from "assets/icons/CustomSelectIcon";
import { CustomAutocompleteIcon } from "assets/icons/CustomAutocompleteIcon";
import orderBy from "lodash/orderBy";

export const CourseSelection = () => {
  const dispatch = useDispatch();

  // What we need from Redux
  const courseSelection = useSelector((state: IState) => state.courseSelection);
  const courseConfig = useSelector((state: IState) => state.courseConfig);
  const instances = orderBy(courseConfig?.instances, (c) => c?.name);
  const availableYear = courseConfig?.years;
  const loading = useSelector((state: IState) => state.loadingCourseMap || state.loadingCourseConfig);

  // Query parameters
  const [query, setQuery] = useQueryParams({
    courseCode: StringParam,
    instanceCode: StringParam,
    instanceVersionNumber: NumberParam,
    campusCode: StringParam,
    campus: StringParam,
    status: StringParam,
    year: NumberParam,
    mms1: StringParam,
    mms2: StringParam,
    mmsS: StringParam,
  });

  // Two way sync - Redux to Query Params
  useEffect(() => {
    dispatch(
      setCourseSelection({
        courseCode: query.courseCode,
        instanceCode: query.instanceCode,
        instanceVersionNumber: query.instanceVersionNumber,
        campusCode: query.campusCode,
        campus: query.campus,
        status: query.status,
        year: query.year,
        mms1Code: query.mms1,
        mms2Code: query.mms2,
        mmsSCode: query.mmsS,
      }),
    );
  }, [
    query.courseCode,
    query.instanceCode,
    query.instanceVersionNumber,
    query.year,
    query.mms1,
    query.mms2,
    query.mmsS,
    dispatch,
    query.campusCode,
    query.campus,
    query.status,
  ]);
  const [rendered, setRendered] = useState(false);
  useEffect(() => {
    if (rendered) {
      setQuery({
        courseCode: courseSelection.courseCode || undefined,
        instanceCode: courseSelection.instanceCode || undefined,
        instanceVersionNumber: courseSelection.instanceVersionNumber || undefined,
        campusCode: courseSelection.campusCode || undefined,
        campus: courseSelection.campus || undefined,
        status: courseSelection.status || undefined,
        year: courseSelection.year || undefined,
        mms1: courseSelection.mms1Code || undefined,
        mms2: courseSelection.mms2Code || undefined,
        mmsS: courseSelection.mmsSCode || undefined,
      });
    } else {
      setRendered(true);
    }
  }, [
    courseSelection.courseCode,
    courseSelection.instanceCode,
    courseSelection.instanceVersionNumber,
    courseSelection.campusCode,
    courseSelection.campus,
    courseSelection.year,
    courseSelection.status,
    courseSelection.mms1Code,
    courseSelection.mms2Code,
    courseSelection.mmsSCode,
    rendered,
    setQuery,
  ]);

  // The state of the selectors
  const selectedCourseYears = availableYear;
  const selectedYear = selectedCourseYears?.find((y: any) => y == courseSelection.year) ?? null;

  const availableInstances = instances.filter((c: any) => c?.year === selectedYear);
  const selectedInstance =
    availableInstances.find((c: any) => c?.instanceCode === courseSelection.instanceCode) ?? null;

  const mms1Options = (selectedInstance?.mms ?? []).filter(
    (mms: any) => mms.type === "Major" && mms.year == courseSelection.year,
  );
  const mms1 = mms1Options.find((mms: any) => mms.code === courseSelection.mms1Code) ?? null;
  const mms2Options = (selectedInstance?.mms ?? []).filter(
    (mms: any) => mms.code !== mms1?.code && mms.year == courseSelection.year,
  );
  const mms2 = mms2Options.find((mms: any) => mms.code === courseSelection.mms2Code) ?? null;

  const mmsSOptions = (selectedInstance?.mms ?? []).filter(
    (mms: any) => mms.type === "Specialisation" && mms.year == courseSelection.year,
  );
  const mmsS = mmsSOptions.find((mms: any) => mms.code === courseSelection.mmsSCode) ?? null;

  const [yearSelectorOpen, toggleYearSelectorOpen] = useState(false);

  // Callbacks for user selection
  const onMMS2Change = (_e: any, mms: IMMSummary | null) => {
    dispatch(selectMMS2(mms?.code));
  };
  const onMMS1Change = (_e: any, mms: IMMSummary | null) => {
    dispatch(selectMMS1(mms?.code));
  };
  const onMMSSChange = (_e: any, mms: IMMSummary | null) => {
    dispatch(selectMMSS(mms?.code));
  };
  const onYearChange = (e: React.ChangeEvent<{ value: any }>) => {
    dispatch(selectYear(Number(e.target.value)));
  };

  const onInstanceChange = (_e: any, instance: ICourseInstance | null) => {
    dispatch(selectInstance(instance));
  };

  const onYearOpen = (_e: any) => {
    toggleYearSelectorOpen(true);
  };
  const onYearClose = (_e: any) => {
    toggleYearSelectorOpen(false);
  };

  const disableInstanceSelection = loading || availableInstances.length === 0 || !selectedYear;
  const disableMms1Selection = loading || mms1Options.length === 0;
  const disableMms2Selection = loading || mms2Options.length === 0;
  const disableMmsSSelection = loading || mmsSOptions.length === 0;
  const showMms1Selection = mms1Options.length > 0;
  const showMmsSSelection = mmsSOptions.length > 0;
  const showMms2Selection = false; // mms1 && mms2Options.length > 0;

  return (
    <>
      <Grid tabIndex={-1} container spacing={2} alignItems="flex-end" className="do-not-print">
        <Grid tabIndex={-1} item xs={12} sm={4} md={2}>
          <InputLabel id="year-selector-label" htmlFor="year-selector" required>
            Commencing Year
          </InputLabel>
          <FormControl fullWidth>
            <Select
              variant="outlined"
              id="year-selector"
              disabled={false}
              value={selectedYear ?? ""}
              onChange={onYearChange}
              displayEmpty
              onOpen={onYearOpen}
              onClose={onYearClose}
              IconComponent={() => <CustomSelectIcon open={yearSelectorOpen} disabled={false} />}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {selectedCourseYears?.map((year: any) => (
                <MenuItem key={`year-option-${year}`} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid tabIndex={-1} item xs={12} sm={6} md={6}>
          <InputLabel id="course-selector-label" htmlFor="course-selector" required>
            Course Code
          </InputLabel>
          <FormControl fullWidth>
            <Autocomplete
              id="course-selector"
              options={availableInstances}
              getOptionLabel={(option: any) => `${option.instanceCode} ${option.name} - ${option.campus}`}
              style={{ width: "100%" }}
              disabled={disableInstanceSelection}
              value={selectedInstance}
              onChange={onInstanceChange}
              popupIcon={<CustomAutocompleteIcon disabled={disableInstanceSelection} />}
              renderInput={(params: any) => <TextField {...params} required variant="outlined" />}
            />
          </FormControl>
        </Grid>
        {showMmsSSelection && (
          <Grid tabIndex={-1} item xs={12} sm={6} md={3}>
            <InputLabel id="mms-selector-label" htmlFor="mmss-selector">
              Specialisation
            </InputLabel>
            <FormControl fullWidth>
              <Autocomplete
                id="mmss-selector"
                options={mmsSOptions}
                getOptionLabel={(option: IMMSummary) => option.name}
                style={{ width: "100%" }}
                disabled={disableMmsSSelection}
                value={mmsS}
                onChange={onMMSSChange}
                popupIcon={<CustomAutocompleteIcon disabled={disableMmsSSelection} />}
                renderInput={(params: any) => <TextField {...params} required variant="outlined" />}
              />
            </FormControl>
          </Grid>
        )}
        {showMms1Selection && (
          <Grid tabIndex={-1} item xs={12} sm={6} md={3}>
            <InputLabel id="mm1-selector-label" htmlFor="mms1-selector">
              Major
            </InputLabel>
            <FormControl fullWidth>
              <Autocomplete
                id="mms1-selector"
                options={mms1Options}
                getOptionLabel={(option: IMMSummary) =>
                  `${option.name} ${option.status !== "Active" ? `(${option.status})` : ""}`
                }
                style={{ width: "100%" }}
                disabled={disableMms1Selection}
                value={mms1}
                onChange={onMMS1Change}
                popupIcon={<CustomAutocompleteIcon disabled={disableMms1Selection} />}
                renderInput={(params: any) => <TextField {...params} required variant="outlined" />}
              />
            </FormControl>
          </Grid>
        )}
        {showMms2Selection && (
          <Grid tabIndex={-1} item xs={12} sm={6} md={3}>
            <InputLabel id="mm2-selector-label" htmlFor="mms2-selector">
              Second Major, Minor or Specialisation
            </InputLabel>
            <FormControl fullWidth>
              <Autocomplete
                id="mms2-selector"
                options={mms2Options}
                getOptionLabel={(option: IMMSummary) => option.name}
                style={{ width: "100%" }}
                disabled={disableMms2Selection}
                value={mms2}
                onChange={onMMS2Change}
                popupIcon={<CustomAutocompleteIcon disabled={disableMms2Selection} />}
                renderInput={(params: any) => <TextField {...params} variant="outlined" />}
              />
            </FormControl>
          </Grid>
        )}
      </Grid>
    </>
  );
};
