import { get } from "lib/fetch";

export const GET_AVAILABLE_COURSES = "availableCourses/GET_AVAILABLE_COURSES";

export const getAvailableCourses = () => ({
  type: GET_AVAILABLE_COURSES,
  payload: get(`/courses`).then((response) => {
    return response.data.courses;
  }),
  /*payload: Promise.resolve([
    {
      code: "BABA",
      name: "Bachelor of Arts",
      years: [2021, 2022],
      instances: [
        {
          instanceCode: "ABAW_AW",
          campus: "Albury-Wodonga",
          mms: [
            {
              code: "MJR-LST01",
              name: "LEGAL STUDIES",
              type: "Major",
            },
            {
              code: "MNR-HHE01",
              name: "HISTORY, HERITAGE AND THE ENVIRONMENT",
              type: "Minor",
            },
          ],
        },
      ],
    } as ICourseSummary,
  ]), */
});
