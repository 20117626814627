import React from "react";
import { IComponent, ICourseSelection, QualificationType } from "store/types";
import styled from "styled-components";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import { YearRow } from "./YearRow";
import { YearOneCompulsorySubjects } from "./YearOneCompulsorySubjects";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Hidden, makeStyles, createStyles } from "@material-ui/core";

const MyHidden: any = Hidden;

interface IProps {
  yearComponent: IComponent;
  qualification?: QualificationType;
  courseSelection: ICourseSelection;
}

const YearWrapper = styled.div`
  width: 100%;
  margin-top: 1.5rem;
`;

const RowDelimWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const semeseterDelimeterStyles = makeStyles(() =>
  createStyles({
    delimIcon: {
      fill: "#D2D2D2",
    },
  }),
);

export const CourseYear = ({ yearComponent, qualification, courseSelection }: IProps) => {
  const rows = groupBy(yearComponent.children ?? [], (c) => c.row ?? 0);
  const rowKeys = orderBy(Object.keys(rows), (rk) => Number(rk));
  const delimClasses = semeseterDelimeterStyles();

  return (
    <YearWrapper className="print-on-single-page">
      <h3 tabIndex={0}>{yearComponent.title}</h3>
      {yearComponent.title && yearComponent.title === "Year One" && (
        <YearOneCompulsorySubjects qualification={qualification} courseSelection={courseSelection} />
      )}
      {rowKeys.map((rk, i) => (
        <React.Fragment key={rk}>
          <YearRow key={rk} slots={rows[rk]} courseSelection={courseSelection} />
          {i < rowKeys.length - 1 && (
            <MyHidden mdUp>
              <RowDelimWrapper className="do-not-print">
                <MoreHorizIcon className={delimClasses.delimIcon} fontSize="large" />
              </RowDelimWrapper>
            </MyHidden>
          )}
        </React.Fragment>
      ))}
    </YearWrapper>
  );
};
