import React from "react";
import { IComponent } from "store/types";
import styled from "styled-components";
import { NestedOption } from "./NestedOption";

interface IProps {
  nestedOptions: IComponent[];
}

const MultiSubjectContainer = styled.div`
  margin-top: 1.5rem;
`;

export const NestedOptionList = ({ nestedOptions }: IProps) => {
  return (
    <>
      {nestedOptions.map((n, i) => (
        <MultiSubjectContainer key={`${n.subType}-option-${i}`}>
          <NestedOption nestedOption={n} />
        </MultiSubjectContainer>
      ))}
    </>
  );
};
