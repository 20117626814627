import initialState from "store/initialState";
import { ICourseSelection, ICourseSummary } from "store/types";
import {
  SELECT_COURSE,
  SELECT_INSTANCE,
  SELECT_MMS1,
  SELECT_MMS2,
  SELECT_MMSS,
  SELECT_YEAR,
  SET_SELECTION,
} from "actions/courseSelection";
import minBy from "lodash/minBy";
import sortBy from "lodash/sortBy";

const bestMatchingYearForCourse = (newCourse: Partial<ICourseSummary> | null) => {
  const years = sortBy(newCourse?.years ?? [], (y) => -y);
  const now = new Date();
  // Before-after October logic
  const effectiveYear = now.getMonth() >= 9 ? now.getFullYear() + 1 : now.getFullYear();
  const bestMatchingYear = minBy(years, (y) => Math.abs(y - effectiveYear)) ?? null;
  return bestMatchingYear;
};

const courseSelection = (state = initialState.courseSelection, action: any): ICourseSelection => {
  switch (action.type) {
    case SELECT_COURSE:
      const newCourse = action.payload as Partial<ICourseSummary> | null;
      const bestMatchingYear = bestMatchingYearForCourse(newCourse);
      return { courseCode: newCourse?.code ?? null, year: bestMatchingYear };
    case SELECT_INSTANCE:
      return {
        courseCode: action.payload ? action.payload.parentCode : null,
        instanceCode: action.payload ? action.payload.instanceCode : null,
        instanceVersionNumber: action.payload ? action.payload.instanceVersionNumber : null,
        campusCode: action.payload ? action.payload.campusCode : null,
        campus: action.payload ? action.payload.campus : null,
        status: action.payload ? action.payload.status : null,
        year: state.year,
      };
    case SELECT_YEAR:
      return { courseCode: state.courseCode, year: action.payload };
    case SELECT_MMS1:
      return {
        ...state,
        mms1Code: action.payload,
        mms2Code: state.mms2Code === action.payload ? null : state.mms2Code,
        mmsSCode: null,
      };
    case SELECT_MMS2:
      return { ...state, mms2Code: action.payload };
    case SELECT_MMSS:
      return { ...state, mmsSCode: action.payload };
    case SET_SELECTION:
      return action.payload;
    default:
      return state;
  }
};

export default courseSelection;
