import { fulfilled, failed, pending } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import { GET_COURSE_CONFIG } from "../actions/courseConfig";

const courseConfig = (state = initialState.courseConfig, action: any) => {
  switch (action.type) {
    case failed(GET_COURSE_CONFIG):
    case pending(GET_COURSE_CONFIG):
      return [];
    case fulfilled(GET_COURSE_CONFIG):
      return action.payload;
    default:
      return state;
  }
};

export default courseConfig;
