import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IState } from "store/types";
import Spinner from "components/spinner";
import { CourseSelection } from "./CourseSelection";
import { StackStrechChild, AppContent, AppRoot, ResponsiveCenter, Stack } from "components/Layout";
import { CourseSelectionHeader } from "./CourseSelectionHeader";
import { CourseMap } from "./CourseMap";
import { CourseSelectionFooter } from "./CourseSelectionFooter";
import { getCourseConfig } from "../actions/courseConfig";

export const App = () => {
  const loading = useSelector((state: IState) => state.loadingCourseMap || state.loadingCourseConfig);
  const dispatch = useDispatch();

  // Load the available courses on app start
  useEffect(() => {
    dispatch(getCourseConfig());
  }, [dispatch]);

  return (
    <AppRoot className="app-root">
      <AppContent>
        <ResponsiveCenter>
          <Stack>
            <CourseSelectionHeader />
            <CourseSelection />
            <StackStrechChild>
              <Spinner loading={loading} />
              <CourseMap />
            </StackStrechChild>
          </Stack>
        </ResponsiveCenter>
      </AppContent>
      <CourseSelectionFooter />
    </AppRoot>
  );
};
