import React from "react";
import { ComponentSubType, ComponentType, IComponent, ICourseSelection } from "store/types";
import { Button, Card, CardActions, CardContent, CardHeader, Collapse } from "@material-ui/core";
import styled from "styled-components";
import { slotCardStyles, typeColors } from "components/cardStyles";
import { SubjectList } from "./SubjectList";
import { Subject } from "./Subject";
import { NestedOptionList } from "./NestedOptionList";

interface IProps {
  slot: IComponent;
  expanded: boolean;
  flipExpansion: () => any;
  courseSelection: ICourseSelection;
}

interface ICardDescriptionProps {
  reducePadding: boolean;
}

interface ICourseHomebookUrlProps {
  subType: ComponentSubType;
}

const CardSubheading = styled.p`
  margin-top: 0.5rem;
  margin-bottom: 0rem;
  font-weight: bold;
`;

const CardDescription = styled.div<ICardDescriptionProps>`
  margin-top: ${(props) => (props.reducePadding ? "0.1rem" : "0.5rem")};
`;

const ReferToHandBookLink = styled.div`
  margin-top: 0.5rem;
`;

const CardHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const A = styled.a<ICourseHomebookUrlProps>`
  color: ${(props) => `${typeColors[props.subType]} !important`};
`;

const slotHeading = (slot: IComponent) => {
  if (!slot.subType) {
    return slot.type;
  } else {
    if (
      [
        ComponentSubType.Option,
        ComponentSubType.Specialisation1,
        ComponentSubType.Specialisation2,
        ComponentSubType.Curriculum1,
        ComponentSubType.Curriculum2,
        ComponentSubType.CurriculumSpecialisation,
      ].includes(slot.subType)
    ) {
      return slot.subType;
    } else if (slot.subType === ComponentSubType.Course) {
      if (slot.type === ComponentType.Electives) {
        return "Elective";
      }
      return slot.type;
    } else if (slot.subType === ComponentSubType.PhantomSpecialisation) {
      return ComponentSubType.Specialisation;
    } else if (slot.subType === ComponentSubType.PhantomMajor) {
      return ComponentSubType.Major;
    } else if (slot.subType === ComponentSubType.PhantomSecondMajor) {
      return ComponentSubType.SecondMajor;
    } else return `${slot.subType} ${slot.type}`;
  }
};

export const Slot = ({ slot, expanded, flipExpansion, courseSelection }: IProps) => {
  const subjects = (slot.children ?? []).filter((c) => c.type === ComponentType.Subject);
  const expandable = subjects.length > 1;
  const nestedOptions = (slot.children ?? []).filter(
    (c) => c.type === ComponentType.Component && c.subType === ComponentSubType.Nested,
  );

  const classes = slotCardStyles({ type: slot.subType });

  let specialisationHandbookUrl = "https://handbook.latrobe.edu.au/";
  if (courseSelection.instanceCode && courseSelection.campusCode && courseSelection.year) {
    const instanceCodeWithoutCampus = courseSelection.instanceCode.substring(
      0,
      courseSelection.instanceCode.indexOf(`_${courseSelection.campusCode}`),
    );
    specialisationHandbookUrl += `courses/${courseSelection.year}/${instanceCodeWithoutCampus}?year=${courseSelection.year}`;
  }

  const showHBReference =
    slot.type === ComponentType.Electives ||
    (slot.type === ComponentType.CoreChoice && !(expandable || (!!nestedOptions && nestedOptions.length > 0)));

  return (
    <Card role="cell" tabIndex={0} elevation={2} className={classes.card} style={{ minHeight: "6vh" }}>
      <CardHeader
        color="primary"
        className={classes.cardHeader}
        classes={{ title: classes.cardTitle }}
        title={
          <CardHeadingContainer role="text" aria-label={`${slotHeading(slot)}, ${slot.points} points.`}>
            {slotHeading(slot)}
            <div>{slot.points}&nbsp;CP</div>
          </CardHeadingContainer>
        }
      />
      <CardContent className={classes.cardContent}>
        {slot.subType && slot.subType === ComponentSubType.Option && <CardSubheading>{slot.title}</CardSubheading>}
        {slot.description && slot.subType && (
          <CardDescription
            role="text"
            aria-label={`${slot.description}.`}
            reducePadding={slot.subType ? slot.subType === ComponentSubType.Option : false}
          >
            {slot.description}

            {showHBReference && (
              <ReferToHandBookLink>
                <A subType={slot.subType} href={specialisationHandbookUrl} target="_">
                  Please refer to the Handbook
                </A>
              </ReferToHandBookLink>
            )}
          </CardDescription>
        )}

        {subjects.length === 1 && <Subject subject={subjects[0]} />}

        <Collapse
          in={!!nestedOptions && nestedOptions.length > 0 && expanded}
          mountOnEnter
          unmountOnExit
          className="do-not-print"
        >
          <NestedOptionList nestedOptions={nestedOptions} />
        </Collapse>

        {!!nestedOptions && nestedOptions.length > 0 && (
          <div className="print-only" aria-hidden="true" role="none">
            <NestedOptionList nestedOptions={nestedOptions} />
          </div>
        )}

        <Collapse in={expandable && expanded} mountOnEnter unmountOnExit className="do-not-print">
          <SubjectList subjects={subjects} />
        </Collapse>
        {expandable && (
          <div className="print-only" aria-hidden="true" role="none">
            <SubjectList subjects={subjects} />
          </div>
        )}
      </CardContent>
      {(expandable || (!!nestedOptions && nestedOptions.length > 0)) && (
        <CardActions classes={{ root: classes.cardActions }} className="do-not-print">
          <Button
            classes={{ root: classes.cardActionButton, label: classes.cardActionButtonLabel }}
            variant="text"
            onClick={flipExpansion}
          >
            {expanded ? "Collapse" : "Expand"}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};
