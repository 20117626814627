import { failed, rootAction } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import { GET_AVAILABLE_COURSES } from "actions/availableCourses";
import { ActionType } from "redux-promise-middleware";
import { GET_COURSE_MAP } from "actions/courseMap";

const error = (state = initialState.error, action: any) => {
  if (action.type && action.type.endsWith(`_${ActionType.Fulfilled}`) && rootAction(action.type) === state?.action) {
    return null;
  }

  switch (action.type) {
    case failed(GET_AVAILABLE_COURSES):
      return {
        action: GET_AVAILABLE_COURSES,
        message: "could not retrieve available courses.",
      };
    case failed(GET_COURSE_MAP):
      return {
        action: GET_COURSE_MAP,
        message: "could not generate course map.",
      };
    default:
      return state;
  }
};

export default error;
