import { ActionType } from "redux-promise-middleware";
import trimEnd from "lodash/trimEnd";

export function pending(type: string) {
  return `${type}_${ActionType.Pending}`;
}

export function fulfilled(type: string) {
  return `${type}_${ActionType.Fulfilled}`;
}

export function failed(type: string) {
  return `${type}_${ActionType.Rejected}`;
}

export function rootAction(type: string) {
  return trimEnd(
    trimEnd(trimEnd(type, `_${ActionType.Pending}`), `_${ActionType.Fulfilled}`),
    `_${ActionType.Rejected}`,
  );
}
