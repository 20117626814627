import { combineReducers } from "redux";
import loadingCourses from "./loadingCourses";
import loadingCourseMap from "./loadingCourseMap";
import availableCourses from "./availableCourses";
import courseSelection from "./courseSelection";
import courseMap from "./courseMap";
import error from "./error";
import courseConfig from "./courseConfig";
import loadingCourseConfig from "./loadingCourseConfig";

export default () =>
  combineReducers({
    loadingCourseMap,
    loadingCourses,
    loadingCourseConfig,
    availableCourses,
    courseConfig,
    courseSelection,
    courseMap,
    error,
  });
