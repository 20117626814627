import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { trim } from "lodash";

interface IProps {
  completionRequirements?: any[];
  additionalInformation?: string;
}

const Heading = styled.h3`
  font-size: 14.4px;
  line-height: 19px;
  margin-top: 3em;
  font-weight: 900;
` as any;

// We're getting some UTF-8 characters in the payloads
function decode(s: string) {
  return s
    .replace("â€™", "'")
    .replace("â€œ", '"')
    .replace("â€“", "-")
    .replace("â€", '"')
    .replace(/À|Á|Â|Ã|Ä|Å/g, ""); // only way i could find to remove Â
}

export const CourseAdditionalDetails = ({ completionRequirements, additionalInformation }: IProps) => {
  let additionalInformationEdited = "";
  if (additionalInformation) {
    additionalInformationEdited = trim(decode(additionalInformation));
  }

  return (
    <>
      {completionRequirements && completionRequirements.length > 0 && (
        <>
          <Heading>COMPLETION REQUIREMENTS</Heading>
          {completionRequirements.map((requirement: any, i: number) => (
            <div key={i}>{ReactHtmlParser(trim(decode(requirement.Description)))}</div>
          ))}
        </>
      )}
      {additionalInformationEdited.length > 0 && (
        <>
          <Heading>ADDITIONAL EXPLANATORY INFORMATION</Heading>
          <div>{ReactHtmlParser(additionalInformationEdited)}</div>
        </>
      )}
    </>
  );
};
