import React from "react";
import { IComponent } from "store/types";
import styled from "styled-components";
import { Subject } from "./Subject";

interface IProps {
  subjects: IComponent[];
}

const MultiSubjectContainer = styled.div`
  margin-top: 1.5rem;
`;

export const SubjectList = ({ subjects }: IProps) => {
  return (
    <>
      {subjects.map((s, i) => (
        <MultiSubjectContainer key={`${s.subType}-${i}`}>
          <Subject subject={s} />
        </MultiSubjectContainer>
      ))}
    </>
  );
};
