import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as DownIcon } from "assets/icons/raw/24px/ltu-chevron-down.svg";
import { brandBlack, grey3 } from "theme/colors";

interface IProps {
  disabled: boolean;
}

export const CustomAutocompleteIcon = (props: IProps) => {
  return (
    <SvgIcon htmlColor={props.disabled ? grey3.toString() : brandBlack.toString()}>
      <DownIcon />
    </SvgIcon>
  );
};
