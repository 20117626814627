import { ICourseInstance, ICourseSelection, ICourseSummary } from "store/types";

export const SELECT_COURSE = "selectedCourse/SELECT_COURSE";
export const SELECT_INSTANCE = "selectedCourse/SELECT_INSTANCE";
export const SELECT_CAMPUS = "selectedCourse/SELECT_CAMPUS";
export const SELECT_YEAR = "selectedCourse/SELECT_YEAR";
export const SELECT_MMS1 = "selectedCourse/SELECT_MMS1";
export const SELECT_MMS2 = "selectedCourse/SELECT_MMS2";
export const SELECT_MMSS = "selectedCourse/SELECT_MMSS";
export const SET_SELECTION = "selectedCourse/SET_SELECTION";

export const selectCourse = (course?: Partial<ICourseSummary> | null) => ({
  type: SELECT_COURSE,
  payload: course ?? null,
});

export const selectInstance = (instance?: any | null) => ({
  type: SELECT_INSTANCE,
  payload: instance ?? null,
});

export const selectCampus = (instance: ICourseInstance | null) => ({
  type: SELECT_CAMPUS,
  payload: instance ?? null,
});

export const selectYear = (year: number) => ({
  type: SELECT_YEAR,
  payload: year,
});

export const selectMMSS = (mmsCode?: string | null) => ({
  type: SELECT_MMSS,
  payload: mmsCode ?? null,
});

export const selectMMS1 = (mmsCode?: string | null) => ({
  type: SELECT_MMS1,
  payload: mmsCode ?? null,
});

export const selectMMS2 = (mmsCode?: string | null) => ({
  type: SELECT_MMS2,
  payload: mmsCode,
});

export const setCourseSelection = (selection: ICourseSelection) => ({
  type: SET_SELECTION,
  payload: selection,
});
