const placeholderSubjects = ["EDU4REI"];
const isYearLevelCreditPlaceholder = (subjectCode: string) =>
  !!subjectCode &&
  (!!placeholderSubjects.find((ps) => ps === subjectCode) || !!subjectCode.trim().match(/^LTU\dYLC$/g));

const baseUrl = "https://handbook.latrobe.edu.au";
export const subjectHandbookUrl = (
  subjectCode: string | undefined,
  year: number | string | undefined,
  instanceCode?: string | null,
) => {
  // Remove the instance suffix, if any
  // For example EBECEO_OL -> EBECEO
  const plainInstanceCode = instanceCode?.replace(/_[^_]+$/g, "")?.trim();

  if (!subjectCode || !year) {
    return baseUrl;
  } else if (isYearLevelCreditPlaceholder(subjectCode)) {
    return plainInstanceCode ? `${baseUrl}/courses/${year}/${plainInstanceCode}` : baseUrl;
  } else {
    return `${baseUrl}/subjects/${year}/${subjectCode}`;
  }
};
