import { flatten, max } from "lodash";
import { IComponent } from "store/types";

export const getUnits = (courseMap: IComponent) => {
  const years = courseMap.children;
  return flatten((years ?? []).map((y) => y.children ?? []));
};

export const getMaxUnitPointSize = (courseMap?: IComponent | null) => {
  if (!courseMap) {
    return 15;
  }
  const units = getUnits(courseMap);
  return max(units.map((u) => Number(u.points ?? 15))) ?? 15;
};
