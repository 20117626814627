import { GET_COURSE_MAP } from "actions/courseMap";
import { fulfilled } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";

const courseMap = (state = initialState.courseMap, action: any) => {
  switch (action.type) {
    case fulfilled(GET_COURSE_MAP):
      return action.payload;
    default:
      return state;
  }
};

export default courseMap;
