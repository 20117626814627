const connectionWords = ["of", "with", "and", "in"];

export const toSentenceCase = (s?: string): string => {
  if (typeof s === "string" && s.length > 0) {
    const words = s.split(" ");
    if (Array.isArray(words) && words.length > 0) {
      if (words.length === 1) {
        const word = words[0];
        const matches = word.charAt(0).match(/\w+/i);
        const lines = word.split("\n");
        if (Array.isArray(lines) && lines.length > 1) {
          return lines
            .map((line) => {
              return toSentenceCase(line);
            })
            .join("\n");
        } else if (Array.isArray(matches)) {
          if (connectionWords.includes(word.toLowerCase())) {
            return word.toLowerCase();
          } else {
            return word
              .split("")
              .map((c, i) => {
                if (i === 0) {
                  return c.toUpperCase();
                }
                return c.toLowerCase();
              })
              .join("");
          }
        } else {
          return word.charAt(0).concat(toSentenceCase(word.slice(1)));
        }
      } else {
        return words.map((word) => toSentenceCase(word)).join(" ");
      }
    }
  }
  return "";
};
