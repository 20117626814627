import { fulfilled, failed, pending } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import { GET_AVAILABLE_COURSES } from "actions/availableCourses";

const availableCourses = (state = initialState.availableCourses, action: any) => {
  switch (action.type) {
    case failed(GET_AVAILABLE_COURSES):
    case pending(GET_AVAILABLE_COURSES):
      return [];
    case fulfilled(GET_AVAILABLE_COURSES):
      return action.payload;
    default:
      return state;
  }
};

export default availableCourses;
